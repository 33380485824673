<template>
    <img
        v-if="url"
        :src="url"
        alt="loading"
    >
</template>

<script setup lang="ts">
import { ref, onUnmounted, onMounted } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type Style from '@openticket/lib-style';
import { injectOrFail } from '../services/util/injectOrFail';

const url = ref<string | null>(null);
const listener = ref<number | undefined>(undefined);

const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const style = injectOrFail<Style>('style');

function setURL(): void {
    switch (style.getAppliedTheme()) {
        case 'dark':
            url.value = whitelabel.resources.spinnerDark;
            break;
        case 'light':
        default:
            url.value = whitelabel.resources.spinner;
            break;
    }
}

onMounted(() => {
    listener.value = style.addThemeListener(() => {
        setURL();
    });

    setURL();
});

onUnmounted(() => {
    if (listener.value !== undefined) {
        style.removeThemeListener(listener.value);
    }
});
</script>

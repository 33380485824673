<template>
    <div class="order-messages">
        <div
            v-if="actionRequired && !isCancelled"
            class="ot-notification is-info clickable"
            role="button"
            tabindex="0"
            @click="$emit('open')"
            @keydown.enter="$emit('open')"
            @keydown.space="$emit('open')"
        >
            <div class="ot-notification-content">
                <div class="ot-notification-content__info-icon">
                    <i class="oti oti-alert" />
                </div>
                <div class="ot-notification-content__text-container">
                    <p class="ot-notification-content__text">
                        {{
                            $t(
                                'order.components.order_messages.action_required.title'
                            )
                        }}
                    </p>
                    <p class="ot-notification-content__sub-text">
                        {{
                            $t(
                                'order.components.order_messages.action_required.subtitle'
                            )
                        }}
                    </p>
                </div>
                <div class="ot-notification-content__btn-icon">
                    <i class="oti oti-arrow-right" />
                </div>
            </div>
        </div>
        <div
            v-if="notYetAvailable && !isCancelled"
            class="ot-notification is-black"
        >
            <div class="ot-notification-content">
                <div class="ot-notification-content__info-icon">
                    <i class="oti oti-lock" />
                </div>
                <div class="ot-notification-content__text-container">
                    <p class="ot-notification-content__text">
                        {{
                            $t(
                                'order.components.order_messages.not_yet_available.title'
                            )
                        }}
                    </p>
                    <p class="ot-notification-content__sub-text">
                        {{
                            $t(
                                'order.components.order_messages.not_yet_available.subtitle'
                            )
                        }}
                        <a
                            v-if="
                                $whitelabel.order.more_info_urls &&
                                    $whitelabel.order.more_info_urls.sealed
                            "
                            :href="$whitelabel.order.more_info_urls.sealed"
                            class="ot-notification-content__sub-text__btn"
                            target="blank"
                        >
                            {{
                                $t(
                                    'order.components.order_ticket_status.more_info'
                                )
                            }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="!isFree && !isCancelled"
            class="order-payment-message"
        >
            <div
                v-if="
                    simplifiedStatus === 'paid' &&
                        $route.params.status === 'paid'
                "
                class="ot-notification is-success"
            >
                <div class="ot-notification-content">
                    <div class="ot-notification-content__info-icon">
                        <i class="oti oti-check" />
                    </div>
                    <div class="ot-notification-content__text-container">
                        <p class="ot-notification-content__text">
                            {{
                                $t(
                                    'order.components.order_messages.success.title'
                                )
                            }}
                        </p>
                        <p class="ot-notification-content__sub-text">
                            {{
                                $t(
                                    'order.components.order_messages.success.subtitle'
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div
                v-else-if="simplifiedStatus === 'pending'"
                class="ot-notification is-warning"
            >
                <div class="ot-notification-content">
                    <div
                        v-if="$order.isPolling"
                        class="ot-notification-content__info-icon"
                    >
                        <div class="ot-spinner" />
                    </div>
                    <div class="ot-notification-content__text-container">
                        <p class="ot-notification-content__text">
                            {{
                                $t(
                                    'order.components.order_messages.pending.title'
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { OrderClient } from '@openticket/lib-order';
import SimplifyStatus from '../../../utils/simplify_status';
import { injectOrFail } from '../../../services/util/injectOrFail';

const order = injectOrFail<OrderClient>('order');

const isFree = computed<boolean>(() => (order.data.finn_price <= 0));

const isCancelled = computed<boolean>(() => (simplifiedStatus.value === 'cancelled'));

const simplifiedStatus = computed<'paid' | 'pending' | 'cancelled' | null>(() => (SimplifyStatus(order.data.status)));

const actionRequired = computed<boolean>(() => {
    for (const ticket of Object.values(order.data.ticket_map)) {
        if (
            !ticket.is_complete
            && ticket.ticket.late_personalization
            && !ticket.invalidated_since
        ) {
            return true;
        }
    }
    return false;
});

const notYetAvailable = computed<boolean>(() => {
    const now = Math.floor(Date.now() / 1000); // unix timestamp
    for (const ticket of Object.values(order.data.ticket_map)) {
        // Show if there is any ticket which is sealed in the future
        if (ticket.retrievable_after && ticket.retrievable_after > now) {
            return true;
        }
    }
    return false;
});
</script>

<style lang="scss" scoped>
.ot-notification {
    display: inline-flex;
    padding: 0.875rem 1.25rem;

    &.clickable {
        cursor: pointer;
    }

    &-content {
        &__sub-text {
            line-height: 0.875rem;
            margin-top: 0;
            font-size: 0.813rem;

            &__btn {
                text-decoration: underline;
                font-size: 0.813rem;
                display: block;
            }
        }

        &__text {
            line-height: 1.25rem;

            &-container {
                overflow: hidden;
                margin-right: 0;
            }
        }

        &__btn-icon {
            margin-left: 1.25rem;
        }

        &__info-icon {
            margin-right: 1.25rem;

            & i:before {
                font-size: 1.3rem;
            }

            & .ot-spinner::after {
                border-bottom-color: var(--ot-color-core-white);
                border-left-color: var(--ot-color-core-white);
                position: relative;
            }
        }
    }
}
</style>

<template>
    <OtPromoBlockList :promo-blocks="promoBlocks" />
</template>

<script async setup lang="ts">
import urlJoin from 'url-join';
import axios from 'axios';
import type { PromoBlockModel } from '@openticket/vue-promoblock-components';
import { Log, send } from '@openticket/lib-log';
import type { OrderClient } from '@openticket/lib-order';
import { StringMessage } from '@openticket/lib-order';
import { ref } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { injectOrFail } from '../../../services/util/injectOrFail';
import { OrderLinkReplacer } from '../../../utils/linkreplacer';

const order = injectOrFail<OrderClient>('order');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const promoBlocks = ref<PromoBlockModel[] | null>(null);
const linkReplacer = new OrderLinkReplacer(order);

async function getPromoBlockData() {
    if (!whitelabel.order.promoblocks_api_url) {
        return;
    }
    const shopId = order.data.shop_id;

    const path = urlJoin(
        whitelabel.order.promoblocks_api_url,
        'shop',
        encodeURIComponent(shopId),
        'promo_block',
    );

    try {
        const { data } = await axios.get<{ data: null | { data: unknown[] } }>(path);
        if (data && data.data && Array.isArray(data.data)) {
            promoBlocks.value = data?.data
                .filter((possibleBlock: unknown): possibleBlock is PromoBlockModel => !!possibleBlock
                    && typeof possibleBlock === 'object' && (
                    'image' in possibleBlock
                        || 'logo' in possibleBlock
                        || 'title' in possibleBlock
                        || 'button' in possibleBlock
                        || 'link' in possibleBlock
                ))
                .map((block: PromoBlockModel) => {
                    if (block.link) {
                        block.link = block.link.replace(linkReplacer, '$1');
                    }

                    return block;
                });
        } else {
            promoBlocks.value = null;
        }
    } catch (e) {
        send(
            new StringMessage(
                'osp.promo_blocks.created.failed_to_fetch',
                'Failed to fetch promo blocks',
                { error: e },
            ),
            Log.Error,
        );
    }
}

// Cannot perform this in top-level due to await needed for axios.
// TODO: Remove in vue-3
void getPromoBlockData();
</script>

<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Investigate whether this eslint disable can be removed -->
<template>
    <div>
        <div
            v-for="event in $order.data.events"
            :key="event.guid"
            :class="`ot-elem-event-${event.guid}`"
        >
            <EventCard
                :event="event"
                :opened-card="openedCard"
                @toggle="toggle"
                @next="emit('next')"
            />

            <RefundRequestCard
                v-if="
                    event.status_until ||
                        (event.refund_setting &&
                            event.refund_setting.status_until)
                "
                :event="event"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import EventCard from './EventCard.vue';
import RefundRequestCard from './RefundRequestCard/RefundRequestCard.vue';

interface Props {
    openedCard?: string | null;
}

type Emits = {
    (e: 'toggle', guid: string): void;
    (e: 'next'): void;
};

withDefaults(defineProps<Props>(), {
    openedCard: null,
});

const emit = defineEmits<Emits>();

function toggle(guid: string): void {
    emit('toggle', guid);
}
</script>

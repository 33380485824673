<template>
    <div class="order-product">
        <OrderItem
            :title="product.product.name"
            :opened="opened"
            :locked="locked"
            :invalidated-reason="product.invalidated_reason"
            :invalidated-since="product.invalidated_since"
            :is-cancelled="isCancelled"
            :is-invalid="isInvalid"
            :is-resold="isResold"
            :has-content="false"
            @toggle="$emit('toggle')"
        >
            <div>
                <div
                    v-if="product.product.description"
                    class="order-product__description ot-text-small"
                >
                    <MarkdownRenderer>
                        {{ product.product.description }}
                    </MarkdownRenderer>
                </div>

                <OrderItemMetadata :metadata="product.metadata" />
            </div>
        </OrderItem>
    </div>
</template>

<script setup lang="ts">
import type { IOrderProduct } from '@openticket/lib-order';
import { computed } from 'vue';
import OrderItem from './OrderItem/OrderItem.vue';
import OrderItemMetadata from './OrderItem/OrderItemMetadata.vue';
import MarkdownRenderer from '../../../components/MarkdownRenderer.vue';

interface Props {
    product: IOrderProduct;
    opened: boolean;
    locked: boolean;
}

const props = defineProps<Props>();

const refundInProgress = computed<boolean>(() => (
    !!props.product.invalidated_since
        && (props.product.invalidated_reason === 'return by visitor'
            || props.product.invalidated_reason === 'returned')
));

const isInvalid = computed<boolean>(() => (!!props.product.invalidated_reason && !refundInProgress.value));

const isResold = computed<boolean>(() => (
    !!props.product.invalidated_since
        && props.product.invalidated_reason === 'ticket is ticketswapped'
));

const isCancelled = computed<boolean>(() => (
    props.product.invalidated_reason === 'ticket-cancelled'
        || props.product.invalidated_reason === 'product-cancelled'
));

defineExpose({
    refundInProgress,
    isInvalid,
    isResold,
});
</script>

<style lang="scss" scoped>
.order-product {
    &__description {
        margin: 0 1.25rem 1.25rem;
    }
}
</style>

<template>
    <div class="order-download-button">
        <Collapse>
            <div v-show="state !== 'hide'">
                <a
                    v-if="state === 'done' && $order.data.download_link"
                    :href="$order.data.download_link"
                    target="_blank"
                >
                    <button class="ot-button is-large">
                        <i class="ot-button-icon--left oti oti-download" />
                        {{ $t('order.components.order.download_button') }}
                    </button>
                </a>

                <button
                    v-else-if="state === 'polling'"
                    class="
                        ot-button
                        is-dark is-large
                        order-download-button__polling
                    "
                >
                    <div class="ot-button-icon--left ot-spinner" />
                    {{ $t('order.components.order.rendering_button') }}
                </button>

                <button
                    v-else-if="state === 'polling-stopped'"
                    class="ot-button is-large"
                    @click="$order.startPolling()"
                >
                    {{ $t('order.components.order.refresh_button') }}
                </button>
            </div>
        </Collapse>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { OrderClient } from '@openticket/lib-order';
import { injectOrFail } from '../../../services/util/injectOrFail';

const order = injectOrFail<OrderClient>('order');

const state = computed<'polling' | 'polling-stopped' | 'hide' | 'done'>(() => {
    if (order.data.status === 'paid') {
        if (order.data.download_ready) {
            return 'done';
        } if (!order.data.is_complete) {
            return 'hide';
        } if (order.isPolling) {
            return 'polling';
        }
        return 'polling-stopped';
    }
    return 'hide';
});
</script>

<style lang="scss" scoped>
.order-download-button {
    margin-bottom: 1rem;

    &__polling {
        pointer-events: none;
    }

    .ot-spinner {
        margin: 0;
        margin-right: 1rem;

        &::after {
            position: relative;
            border-left-color: var(--ot-color-core-background-primary);
            border-bottom-color: var(--ot-color-core-backgroudn-primary);
        }
    }
}
</style>

<template>
    <div class="booker-info">
        <div class="booker-info__item booker-info__item__first-name">
            <span class="ot-text-tiny">{{
                $t('shop.common.metaData.first_name')
            }}</span>
            <h4>{{ $order.data.firstName }}</h4>
        </div>

        <div class="booker-info__item booker-info__item__last-name">
            <span class="ot-text-tiny">{{
                $t('shop.common.metaData.last_name')
            }}</span>
            <h4>{{ $order.data.lastName }}</h4>
        </div>

        <div class="booker-info__item booker-info__item__email">
            <span class="ot-text-tiny">{{
                $t('shop.common.metaData.email')
            }}</span>
            <h4>{{ $order.data.email }}</h4>
        </div>

        <OrderItemMetadata
            v-if="orderMetaData.length"
            :metadata="orderMetaData"
        />
    </div>
</template>

<script setup lang="ts">
import type { IOrderMetaData, OrderClient } from '@openticket/lib-order';
import { computed } from 'vue';
import OrderItemMetadata from './OrderItem/OrderItemMetadata.vue';
import { injectOrFail } from '../../../services/util/injectOrFail';

const order = injectOrFail<OrderClient>('order');

const orderMetaData = computed<IOrderMetaData[]>(() => order.data.metadata.map((metadata: IOrderMetaData) => ({
    ...metadata,
    item: metadata.metadata,
})));
</script>

<style lang="scss" scoped>
.booker-info {
    padding-top: 1.25rem;
    border-bottom: 2px solid var(--ot-shop-color-box-accent);

    &__item {
        margin-bottom: 1rem;
        padding: 0 1.25rem;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 1.25rem;
        }
    }
}
</style>

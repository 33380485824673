<template>
    <div
        class="ot-card has-no-padding order-item"
        :class="{
            disabled:
                isInvalid ||
                isResold ||
                isCancelled ||
                simplifiedStatus === 'cancelled',
            'ot-highlight': opened && hasContent,
            'has-content': hasContent,
        }"
    >
        <div
            class="order-item__heading"
            data-testid="order-item-heading"
            role="button"
            tabindex="0"
            @click="$emit('toggle')"
            @keydown.enter="$emit('toggle')"
            @keydown.space="$emit('toggle')"
        >
            <OrderItemHeading
                :is-cancelled="isCancelled"
                :is-resold="isResold"
                :is-invalid="isInvalid"
                :needs-personalization="needsPersonalization"
                :personalization-complete="personalizationComplete"
                :title="title"
                :count-title="countTitle"
                :subtitle="subtitle"
                :seat-label="seatLabel"
                :simplified-status="simplifiedStatus"
            />

            <OrderItemActions
                :is-cancelled="isCancelled"
                :is-sealed="isSealed"
                :is-resold="isResold"
                :is-invalid="isInvalid"
                :opened="opened"
                :locked="locked"
                :has-content="hasContent"
                :needs-personalization="needsPersonalization"
                :wallet-enabled="walletEnabled"
                :refund-in-progress="refundInProgress"
                :simplified-status="simplifiedStatus"
            >
                <template #actions>
                    <slot name="actions" />
                </template>
            </OrderItemActions>
        </div>
        <Collapse v-if="hasContent">
            <div
                v-show="opened"
                class="order-item__content"
                data-testid="collapse-content"
            >
                <slot />
            </div>
        </Collapse>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import OrderItemActions from './OrderItemActions.vue';
import OrderItemHeading from './OrderItemHeading.vue';

@Component({
    components: { OrderItemActions, OrderItemHeading },
})
export default class OrderItem extends Vue {

    @Prop() locked!: boolean;
    @Prop() personalizationComplete!: boolean;
    @Prop() opened!: boolean;
    @Prop() hasContent!: boolean;

    @Prop() title!: string;
    @Prop() countTitle!: string | null;
    @Prop() subtitle!: string | null;
    @Prop() seatLabel!: string | null;

    @Prop({ default: false }) isInvalid!: boolean;
    @Prop({ default: false }) isCancelled!: boolean;
    @Prop({ default: false }) isResold!: boolean;
    @Prop({ default: false }) isSealed!: boolean;
    @Prop({ default: false }) refundInProgress!: boolean;
    @Prop({ default: false }) needsPersonalization!: boolean;
    @Prop({ default: false }) walletEnabled!: boolean;
    @Prop({ default: null }) simplifiedStatus!:
        | 'paid'
        | 'pending'
        | 'cancelled'
        | null;

}
</script>

<style lang="scss" scoped>
.order-item {
    border-radius: var(--ot-card-border-radius);
    transition: var(--ot-transition-default);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
        0px 0px 0px 1.5px rgba(0, 0, 0, 0.05);

    &__content {
        display: flow-root;
    }

    &__heading {
        min-height: 5.5rem;
        padding: 0rem 1.25rem;
        box-sizing: border-box;
        display: flex;
        width: 100%;
    }

    &.has-content {
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
    }

    &.disabled & {
        &__content {
            flex: 1;
            display: flex;
            align-items: center;
            text-align: left;
        }
        &__heading {
            opacity: 0.5;
        }
    }
}
</style>

<template>
    <div class="back-to-shop">
        <a :href="url"><button class="ot-button is-large">
            <i class="ot-button-icon--left oti oti-check" />
            {{ $t('order.components.back_to_shop.button') }}
        </button></a>
    </div>
</template>

<script setup lang="ts">
import urlJoin from 'url-join';
import { computed } from 'vue';
import type { OrderClient } from '@openticket/lib-order';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { injectOrFail } from '../../../services/util/injectOrFail';

const order = injectOrFail<OrderClient>('order');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const url = computed<string>(() => urlJoin(whitelabel.shop.url, order.data.shop_id));
</script>

<style lang="scss" scoped>
.back-to-shop {
    text-align: center;
    margin-bottom: 1rem;
}
</style>

<template>
    <div class="ot-card print-order">
        <a
            role="button"
            tabindex="0"
            @click="print"
            @keydown.enter="print"
            @keydown.space="print"
        ><button
            class="ot-button is-large"
            :class="{ 'is-loading': loading }"
        >
            <i class="ot-button-icon--left oti oti-export" />
            {{ $t('order.components.print_order.button') }}
        </button></a>
    </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import urlJoin from 'url-join';
import { ref } from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import type { OrderClient } from '@openticket/lib-order';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { injectOrFail } from '../../../services/util/injectOrFail';
import VueError from '../../../error';

const order = injectOrFail<OrderClient>('order');
const notifications = injectOrFail<VueNotifications>('notifications');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const loading = ref<boolean>(false);

const print = async (): Promise<void> => {
    if (!whitelabel.order.legacy_api_url) {
        throw new VueError('Legacy API url is not set.', new Error(), 'osp.errors.missing_legacy_api_url');
    }

    const adyenWebhookRoute = urlJoin(
        whitelabel.order.legacy_api_url,
        'payment/webhook/adyen',
    );

    if (loading.value) {
        return;
    }

    loading.value = true;

    try {
        const data = {
            printOrder: 'true',
            notificationItems: [
                {
                    NotificationRequestItem: {
                        merchantReference: order.data.guid,
                    },
                },
            ],
        };

        const response = await axios.post<string>(adyenWebhookRoute, data);
        notifications.success(response.data);
    } catch (e) {
        if (e && typeof e === 'object' && 'message' in e && typeof e.message === 'string') {
            notifications.warning(e.message);
        }
    } finally {
        loading.value = false;
    }
};
</script>

<style lang="scss" scoped>
.print-order {
    text-align: center;
    margin-bottom: 1rem;
}
</style>

export interface OrderLinkReplacerData {
    data: {
        email: string;
        firstName: string;
        guid: string;
        lastName: string;
        shop_id: string;
        status: string;
    };
}

export interface OrderTicketLinkReplacerData {
    guid: string;
    ticket: {
        event_id: string;
        guid: string;
    };
}

export class OrderLinkReplacer {

    order: OrderLinkReplacerData;
    ticket: OrderTicketLinkReplacerData | undefined;

    constructor(order: OrderLinkReplacerData, ticket?: OrderTicketLinkReplacerData) {
        this.order = order;
        this.ticket = ticket;
    }

    [Symbol.replace](link: string): string {
        const replaceValues: Array<[ RegExp, string ]> = [
            [ /{{\s*(order_id)\s*}}/g, encodeURIComponent(this.order.data.guid) ],
            [ /{{\s*(shop_id)\s*}}/g, encodeURIComponent(this.order.data.shop_id) ],
            [ /{{\s*(order_status)\s*}}/g, encodeURIComponent(this.order.data.status) ],

            [ /{{\s*(first_name)\s*}}/g, encodeURIComponent(this.order.data.firstName) ],
            [ /{{\s*(last_name)\s*}}/g, encodeURIComponent(this.order.data.lastName) ],
            [ /{{\s*(email)\s*}}/g, encodeURIComponent(this.order.data.email) ],

            [ /{{\s*(event_id)\s*}}/g, encodeURIComponent(this.ticket ? this.ticket.ticket.event_id : '') ],
            [ /{{\s*(ticket_id)\s*}}/g, encodeURIComponent(this.ticket ? this.ticket.ticket.guid : '') ],
            [ /{{\s*(order_ticket_id)\s*}}/g, encodeURIComponent(this.ticket ? this.ticket.guid : '') ],
        ];

        const replaced = replaceValues.reduce((carry: string, [ regex, value ]) => carry.replace(regex, value), link);

        return removeEmptyQueryParams(replaced);
    }

}

function removeEmptyQueryParams(url: string | URL): string {
    try {
        const parsed = new URL(url);
        const params = new URLSearchParams();

        for (const [ key, value ] of parsed.searchParams.entries()) {
            if (value) {
                params.set(key, value);
            }
        }

        parsed.search = params.toString();
        return parsed.toString();
    } catch (e) {
        console.warn('Failed to parse url', e);

        return '';
    }
}

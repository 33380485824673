<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Investigate whether this eslint disable can be removed -->
<template>
    <div
        v-if="show"
        class="ot-card resell"
    >
        <h4 class="resell__title">
            {{ $t('order.components.resell.title') }}
        </h4>
        <div class="ot-text-body resell__subtitle">
            {{ $t('order.components.resell.subtitle') }}
        </div>
        <a
            v-if="showTicketSwap"
            target="_blank"
            class="resell__link ticketswap"
            :href="ticketSwapUrl"
        >
            <button class="ot-button is-fullwidth">
                {{ $t('order.components.resell.button') }}
                <img
                    class="ot-button-icon--right"
                    height="20px"
                    src="../../../assets/images/brands/ticketswap_white.png"
                    alt="Ticketswap"
                >
            </button>
        </a>
        <a
            v-if="showTixel"
            target="_blank"
            class="resell__link tixel"
            :href="tixelUrl"
        >
            <button class="ot-button is-fullwidth">
                {{ $t('order.components.resell.button') }}
                <img
                    class="ot-button-icon--right"
                    height="20px"
                    src="../../../assets/images/brands/tixel_white.png"
                    alt="Tixel"
                >
            </button>
        </a>
        <div class="resell__footnote ot-label">
            {{ $t('order.components.resell.footer') }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { OrderClient } from '@openticket/lib-order';
import { injectOrFail } from '../../../services/util/injectOrFail';

const order = injectOrFail<OrderClient>('order');
const settings = injectOrFail<CustomShopSettingsClient>('settings');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const show = ref<boolean>(false);

const showTicketSwap = computed<boolean>(() => (
    !!whitelabel.order.ticketswap_resell_url
        && !!settings?.static.order.enableTicketSwap
));

const ticketSwapUrl = computed<string>(() => (
    whitelabel.order.ticketswap_resell_url + order.data.guid
));

const showTixel = computed<boolean>(() => (
    !!whitelabel.order.tixel_resell_url
        && !!settings?.static.order.enableTixel
));

const tixelUrl = computed<string>(() => (whitelabel.order.tixel_resell_url + order.data.guid));

function shouldShow(): boolean {
    for (const ticket of Object.values(order.data.ticket_map)) {
        if (ticket.retrievable_after) {
            return showTicketSwap.value || showTixel.value;
        }
    }
    return false;
}

show.value = shouldShow();
</script>

<style lang="scss" scoped>
.resell {
    text-align: center;
    margin-bottom: 1rem;

    &__title {
        margin-top: 0.5rem;
    }

    &__subtitle {
        margin-bottom: 1.5rem;
    }

    &__footnote {
        margin-top: 1rem;
    }

    &__link button {
        margin-bottom: 1rem;
    }

    &__link.tixel img {
        margin-top: -0.3rem;
    }
}
</style>

<template>
    <div
        v-if="show"
        class="ot-card has-no-padding green-product"
        role="button"
        tabindex="0"
        @click="fireConfetti"
        @keydown.enter="fireConfetti"
        @keydown.space="fireConfetti"
    >
        <CardSection
            class="green-product__header"
            separator
        >
            <div class="green-product__header__content">
                <a
                    href="https://treesforall.nl/en/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <img
                        alt="Trees for All"
                        src="../../../../assets/images/upgrades/logo-trees-for-all.svg"
                        class="green-product__header__content__logo"
                    >
                </a>

                <h3 class="green-product__header__content__title">
                    {{
                        $t(
                            'order.components.upgrades.green_product.header.content.title'
                        )
                    }}
                </h3>

                <span
                    class="green-product__header__content__subtitle ot-text-tiny"
                >{{
                    $t(
                        'order.components.upgrades.green_product.header.content.subtitle'
                    )
                }}</span>
            </div>
            <div class="green-product__header__image" />
        </CardSection>

        <CardSection
            class="green-product__content"
            separator
        >
            <img
                ref="green-tree"
                class="green-product__content__icon"
                src="../../../../assets/images/icons/tree.png"
                alt="Tree"
            >

            <h3 class="green-product__content__title">
                {{
                    $t('order.components.upgrades.green_product.content.title')
                }}
            </h3>

            <p class="green-product__content__text ot-text-small">
                <MarkdownRenderer>
                    {{ $t('order.components.upgrades.green_product.content.text') }}
                </MarkdownRenderer>
            </p>

            <a
                href="https://treesforall.nl/en/"
                target="_blank"
                rel="noreferrer noopener"
            >
                <img
                    alt="Trees for All"
                    src="../../../../assets/images/upgrades/logo-trees-for-all.svg"
                    class="green-product__content__logo"
                >
            </a>
        </CardSection>

        <div class="confetti">
            <canvas
                ref="canvas"
                class="confetti__canvas"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import Confetti from 'canvas-confetti';
import type { IOrderGlobalProduct, OrderClient } from '@openticket/lib-order';
import {
    ref, onMounted, onBeforeUnmount, computed,
} from 'vue';
import CardSection from '../CardSection.vue';
import { injectOrFail } from '../../../../services/util/injectOrFail';
import MarkdownRenderer from '../../../../components/MarkdownRenderer.vue';

const productClass = 'Co2Compensate';

const order = injectOrFail<OrderClient>('order');

const confetti = ref<InstanceType<typeof HTMLCanvasElement> | null>(null);
const greenTree = ref<InstanceType<typeof Element> | null>(null);

const observer = ref<IntersectionObserver | null>(null);
const confettiFn = ref<Confetti.CreateTypes | null>(null);

const show = computed<boolean>(() => !!order.data.global_products.find(
    (product: IOrderGlobalProduct) => product.product.class === productClass,
));

onMounted(() => {
    unobserve();

    initObserver();
});

onBeforeUnmount(() => {
    unobserve();
});

const fireConfetti = (): void => {
    if (confettiFn.value && greenTree.value) {
        const boundingRect: DOMRect = greenTree.value.getBoundingClientRect();
        const vh: number = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0,
        );

        void confettiFn.value({
            colors: [ '#00c805' ],
            spread: 360,
            startVelocity: 30,
            particleCount: 200,
            origin: {
                x: 0.5,
                y: (boundingRect.top + boundingRect.height / 2) / vh,
            },
        });
    }
};

function unobserve(): void {
    if (observer.value) {
        observer.value.disconnect();
        observer.value = null;
    }
}

function handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
            unobserve();

            fireConfetti();
        }
    });
}

function initObserver(): void {
    if (observer.value || !show.value) {
        return;
    }

    if (!confetti.value || !greenTree.value) {
        return;
    }

    // IntersectionObserver is a relatively new feature,
    // Confetti is not deemed critical, therefore, just ignore if it doesn't exist.
    if ('IntersectionObserver' in window) {
        observer.value = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => handleIntersection(entries),
            { threshold: 1.0 },
        );

        confettiFn.value = Confetti.create(confetti.value, {
            resize: true,
            useWorker: true,
        });

        observer.value.observe(greenTree.value);
    }
}
</script>

<style lang="scss" scoped>
.green-product {
    margin-bottom: 1rem;
    overflow-x: hidden;
    border: 1px solid #00c805;

    &__header {
        box-sizing: border-box;
        display: flex;
        padding: 0;

        * {
            box-sizing: border-box;
        }

        &__content {
            width: 50%;
            padding: 1.25rem;

            &__subtitle {
                word-break: break-word;
            }

            &__logo {
                height: 1.25rem;
            }
        }

        &__image {
            display: flex;
            width: 50%;
            background-image: url('../../../../assets/images/upgrades/green-trees.png');
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: cover;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        padding: 1.5rem 2.5rem;

        &__icon {
            height: 2rem;
        }

        &__logo {
            height: 1.75rem;
        }
    }

    .confetti {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 999;

        &__canvas {
            width: 100%;
            height: 100%;
        }
    }
}
</style>

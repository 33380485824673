<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Investigate whether this eslint disable can be removed -->
<template>
    <footer
        class="footer"
        :class="{ 'footer--dark': dark }"
    >
        <div class="footer__row">
            <div class="footer__row__logo">
                <a
                    v-show="showPoweredBy"
                    target="_blank"
                    :href="whitelabelWebsiteUrl"
                >
                    <span>{{ $t('shop.components.footer.powered_by') }}</span>
                    <SiteLogo />
                </a>
            </div>
            <div
                v-show="showLocaleSwitch"
                class="footer__row__locale"
                role="button"
                tabindex="0"
                @click="otLocaleSwitch?.toggle()"
                @keydown.space="otLocaleSwitch?.toggle()"
                @keydown.enter="otLocaleSwitch?.toggle()"
            >
                <OtLocaleFlag
                    :locale="$localization.locale"
                    size="short"
                />

                <i
                    class="oti is-small"
                    :class="localeSwitchOpen ? 'oti-drop-up' : 'oti-drop-down'"
                />
            </div>
        </div>

        <OtLocaleSwitch
            ref="otLocaleSwitch"
            class="footer__locale-switch"
            data-testid="footer-locale-switch"
            @opened="openLocaleSwitch"
        />

        <div
            v-if="!hideCookieLink"
            class="ot-button is-text is-small"
            :title="$t('shop.components.footer.manage_cookies.title')"
            role="button"
            tabindex="0"
            @keydown.space="$emit('openCookieWall')"
            @keydown.enter="$emit('openCookieWall')"
            @click="$emit('openCookieWall')"
        >
            {{ $t('order.components.footer.manage_cookies.text') }}
        </div>
    </footer>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import OtLocaleFlag from '@openticket/vue-localization/src/components/OtLocaleFlag.vue';
import OtLocaleSwitch from '@openticket/vue-localization/src/components/OtLocaleSwitch.vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { injectOrFail } from '../services/util/injectOrFail';

interface Props {
    dark?: boolean;
    showPoweredBy?: boolean;
    showLocaleSwitch?: boolean;
    hideCookieLink?: boolean;
}

withDefaults(defineProps<Props>(), {
    dark: false,
    showPoweredBy: true,
    showLocaleSwitch: true,
    hideCookieLink: false,
});

const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const otLocaleSwitch = ref<InstanceType<typeof OtLocaleSwitch> | null>(null);
const localeSwitchOpen = ref(false);

const whitelabelWebsiteUrl = computed(() => whitelabel.website_url || '');

function openLocaleSwitch(opened: boolean): void {
    localeSwitchOpen.value = opened;
}
</script>

<style lang="scss" scoped>
.footer {
    &__row {
        margin: 0.75rem 0;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        & > * {
            margin: 0.75rem 0;
        }

        &__logo {
            position: relative;
            z-index: 5;

            a {
                color: inherit;
                text-decoration: none;
            }

            span,
            img {
                vertical-align: middle;
            }

            img {
                height: 1.25rem;
            }

            span {
                margin-right: 0.5rem;
                color: var(--color-canvas-invert);
                font-size: 0.75rem;
            }
        }

        &__locale {
            display: flex;

            cursor: pointer;

            .oti {
                margin-left: 0.5rem;
            }
        }
    }

    &__locale-switch {
        margin: var(--ot-spacing-default, 1rem) 0;
    }

    &--dark & {
        &__logo {
            span {
                color: var(--color-card-dark-invert);
            }
        }
    }
}

@media (max-width: 25rem) {
    .footer__row {
        flex-direction: column;
    }
}
</style>

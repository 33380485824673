<!-- eslint-disable vue/multi-word-component-names -->
<!-- TODO: Investigate whether this eslint disable can be removed -->
<template>
    <div class="collapse">
        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
        >
            <slot />
        </transition>
    </div>
</template>

<script setup lang="ts">
const isElementWithStyleHeight = (el: Element): el is Element & { style: { height: string } } => !!el
    && typeof el === 'object' && 'style' in el && !!el.style && typeof el.style === 'object'
    && 'height' in el.style && typeof el.style.height === 'string';

const start = (el: Element): void => {
    if (isElementWithStyleHeight(el)) {
        el.style.height = `${el.scrollHeight}px`;
    }
};

const end = (el: Element): void => {
    if (isElementWithStyleHeight(el)) {
        el.style.height = '';
    }
};
</script>
